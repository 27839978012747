/* local css */
.css-169iwlq-MuiCalendarPicker-root{
    background-color: #030303;
}

.css-7cyu3b-MuiButtonBase-root-MuiPickersDay-root{
    background-color: #151517 !important;
}

.css-16qx2sz-MuiButtonBase-root-MuiPickersDay-root{
    background-color: #674EFF !important;
}

canvas.chartjs-render-monitor{
    height: 400px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
    background:black !important;
}
.css-1jm66jt-MuiClockPicker-root{
    background: black !important;
}


/* server side css  */

.css-1brzq0m{
    background: black !important;
}

.css-1l2b3vk{
    background: black !important;
}

.css-30b4aj{
    background: #674EFF !important;
}

.css-r8u8y9{
    background: black !important;
}